// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
@import "../../sass/assets/text-stroke";
body{
    font-family: "メイリオ", sans-serif;
}
.nc-header{
    display: flex;
    padding: 0 10%;
    &__left{
        width: 69%;
        display: inline-block;
        h1,&--p,span{
            display: inline-block;
        }
        h1{
            width: 100%;
        }
        &--p{
            width: 100%;
            font-size: 0.9vw;
            vertical-align: text-top;
        }
    }
    
    &__right{
        float: right;
        display: flex;
        a.a1{
            width: 230px;
            display: flex;
            float: left;
            justify-content: center;
            align-items: center;
            p{
                padding: 10px 11px;
                border-radius: 5px;
                margin-bottom: 0;
                color: #ffffff;
                font-weight: bold;
                background: linear-gradient(#a5bbed,#92a6d3);
                box-shadow: 4px 4px #6183d2;
            }
        }
        a.a2{
            width: 12rem;
            display: flex;
            float: left;
            justify-content: center;
            align-items: center;
            i{
                padding-top: 3px;
                color:#fff;
                margin-right: 10px;
            }
            p{
                padding: 10px 11px;
                border-radius: 5px;
                margin-bottom: 0;
                color: #ffffff;
                font-weight: bold;
                background: linear-gradient(#f88382,#f56766);
                box-shadow: 4px 4px #ec2f2d;
            }
        }
        a.a1:hover{
            text-decoration: none;
            p{
                background:#5279d0;
            }
        }
        a.a2:hover{
            text-decoration: none;
            p{
                background:#f14e4e;
            }
        }
    }
    @media (max-width: 1200px){
        &__left{
            h1{
                vertical-align: middle;
                display: table-cell;
                float: left;
            }
            &--p{
                font-size: 1.5vw;
            }
        }  
    }
    @media (max-width: 992px){
        padding: 0 5%;
        &__left{
            width: calc(100% - 20%);
            h1{
                width: calc(100% - 20%);
            }
            &--p{
                margin: 0px;
                width: 100%;
            }
        }
        &__right{
            a.a1{
                width: 126px;
                text-align: center;
                font-size: 0.8rem;
                margin-right: 10px;
            }
            a.a2{
                width: 90px;
                text-align: center;
                font-size: 0.8rem;
                margin: 16px 0;
                i{
                    display: none;
                }
            }  
        }
    }
    @media (max-width: 576px){
        h1{
            margin-bottom: 0px;
        }
        &__left{
            width: calc(100% - 10%);
            &--p{
                font-size: 0.5rem;
            }
        }
        &__right{
            display: block;
            margin-top: 10px;
            a.a1{
                float: right;
                width: 125px;
                margin: 0px;
            }
            a.a2{
                float: right;
                width: 125px;
                margin: 10px 0px 0px 0px;
            }
        }
    }
}

.wrapper{
    overflow: hidden;
}
.page-template-page-cliant{
    .nc-header{
        &__right{
            justify-content: flex-end;
            width: 30%;
            a.a2{
                width: 13rem;
            }
        }
        @media (max-width: 576px){
            &__right{
                margin-top: 0px;
                a.a2{
                    width: 6rem;
                    p{
                        padding: 10px 0px;
                    }
                }
            }
        }
    }
}
/**/
.form-group{
    &__yubin{
        &--attention{
            margin: 0px;
            font-size: small;
            color: #000;
        }
    }
}
/*cliant*/
.nc-top{
    position: relative;
    &__pc-only{
        display: initial;
    }
    &__sp-only{
        display: none;
    }
    &__text{
        text-align: center;
        width: 100%;
        position: absolute;
        top: 38%;
        &--01{
            color: #fff;
            position: relative;
            margin: 0 auto;
            width: 440px;
            font-size: 24px;
            font-weight: bold;
            text-shadow:  black 0 0 5px;
            border-bottom: 3px solid #ffff00;
            span{
                font-size: 20px;
            }
        }
        &--01::after{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ffff00;
            display: block;
            content:"";
            position: absolute;
            top: 39px;
            left: 50%;
            right: 50%;
        }
        &--02{
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            text-shadow: #000 0 0 5px;
            span{
                font-size: 60px;
                color: #fcd6d5;
                @include text-stroke(3, #da6969);
            }
            strong{
                font-size: 42px;
            }
        }
        &--03{
            text-shadow: #000 0 0 5px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            small{
                font-size: 14px;
                font-weight: bold;
            }
            span{
                background: linear-gradient(transparent 50%, #ffff00 50%);
                color: #fcd6d5;
                @include text-stroke(2, #da6969);
            }
        }
    }
    &__ul{
        text-align: center;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0;
        li{
            display: inline-block;
        }
        &--01::after{
            background-image: url(../images/icon_note.png);
        }
        &--02::after{
            background-image: url(../images/icon_man.png);
        }
        &--03::after{
            background-image: url(../images/icon_search-p.png);
        }
        &--04::after{
            background-image: url(../images/icon_tag.png);
        }
        &--01,&--02,&--03,&--04{
            background: #fff;
            border-radius: 50%;
            padding: 2% 2%;
            font-weight: bold;
        }
        &--01{
            padding: 2% 2.5%;
        }
        &--04{
            padding: 2% 1.5%;
        }
        &--01::after,&--02::after,&--03::after,&--04::after{
            background-repeat: no-repeat;
            content: "";
            display: block;
            margin-top: 5px;
            height: 6vh;
            background-position: center;
            background-size: contain;
        }
        &--01{
            color:#584b8f;
        }
        &--02{
            color:#7fbdc5;
        }
        &--03{
            color:#c49ebc;
        }
        &--04{
            color:#92a6d3;
        }
    }
    &__span{
        width: 100%;
        position: absolute;
        bottom: 0px;
        display: block;
        height: 8.8%;
        background: linear-gradient(90deg, #e0c3fc, #8ec5fc) ;
    }
    @media (min-width: 1200px) and (max-width: 1500px){
            &__ul{
                li{
                    position: absolute;
                    bottom: 0px;
                }
                &--01{
                    left: 5%;
                }
                &--02{
                    left: 17%;
                }
                &--03{
                    right: 17%;
                }
                &--04{
                    right: 5%;
                }
            }
        }
    @media (max-width: 1200px) {
        &__ul{
            display: none;
        }
    }
    @media (max-width: 992px){
        &__text{
            &--02{
                span,strong{
                    font-size: 40px;
                }
            }
        } 
    }
    @media (max-width: 768px){
        &__text{
            &--01{
                font-size: 1rem;
                width: 288px;
                span{
                    font-size: 15px;
                }
            }
            &--01::after{
                top: 27px;
            }
            &--02{
                span,strong{
                    font-size: 30px;
                }
            }
            &--03{
                font-size: 18px;
            }
        }
    }
    @media (max-width: 576px){    
        margin-top: 20px;
        &__pc-only{
            display: none;
        }
        &__sp-only{
            height: 615px;
            display: initial;
        }
        &__ul{
            display: block;
            padding: 0 20px;
            span{
                display: block;
                margin-bottom: 10px;
            }
            &--01{
                padding: 8% 10.5%;
            }
            &--04{
                padding: 8% 6.5%;
            }
            &--02,&--04{
                margin: 0 20px;
            }
            &--02,&--03{
                padding: 8% 8.5%;
            }
            &--01::after,&--02::after,&--03::after,&--04::after{
                height: 6vh;
            }
        }
        &__span{
            display:none;
        }
        &__text{
            top: 5%;
            padding: 0 20px;
            &--br{
                display: none;
            }
            &--01{
                font-size: 1.2rem;
                width: 100%;
            }
            &--01::after{
                bottom: -8px;
                top: inherit;
            }
            &--02{
                margin-top: 20px;
                font-size: 2rem;
                margin-bottom: 0.5rem;
                span{
                    font-size: 2rem;
                }
                strong{
                    font-size: 2rem;
                }
            }
            &--03{
                font-size: 1rem;
                small{
                    font-size: 1rem;
                }
            }
        } 
    }
    @media (max-width: 420px){
        &__ul{
            &--01{
                padding: 6vw 8vw;
            }
            &--04{
                padding: 6vw 4vw;
            }
            &--02,&--04{
                margin: 0px;
            }
            &--02,&--03{
                padding: 6vw;
            }
        }
    }
}
/*home*/
#main{
    width: 100%;
    .container-fluid{
        margin-top: 50px;
    }
    .characteristic{
        margin-bottom: 100px;
        h2{
            text-align: center;
            font-size: 34px;
            font-weight: bold;
            span{
                font-size: 60px;
                color:#218896;
            }
            small{
                font-size: 40px;
                font-weight: bold;
            }
        }
        &__content{
            height: 24rem;
            text-align: center;
            padding: 11% 30px 10px 30px;
            position: relative;
            &--title{
                font-size: 20px;
                position: absolute;
                background: yellow;
                top: -22px;
                left: -18px;
                padding: 21px 66px;
                p{
                    display: inline-block;
                    position: absolute;
                    left: 31px;
                    top: 7px;
                    font-weight: bold;
                    text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff,0 2px 0 #fff,0 -2px 0 #fff,-2px 0 0 #fff,2px 0 0 #fff;
                }
                img{
                    position: absolute;
                    top: -18px;
                    left: 74px;
                }
            }
            &--title::after{
                content:"";
                display: block;
                width: 0;
                height: 0;
                border-left: 28px solid transparent;
                border-right: 0px solid transparent;
                border-top: 10px solid yellow;
                position: absolute;
                bottom: -10px;
                left: 15px;
            }
            &--title01{
                color:#7ad78c;
            }
            &--title02{
                color:#15aee9;
            }
            &--title03{
                color:#f77776;
            }
            &--p01{
                font-size: 22px;
                font-weight: bold;
            }
            &--p02{
                font-size: 15px;
                text-align: left;
            }
        }
        &__content01{
            border: 10px solid #7ad78c;
            img{
                padding: 0 0 20px 0;
            }
        }
        &__content02{
            border: 10px solid #15aee9;
            img{
                padding: 0 0 20px 0;
            }
        }
        &__content03{
            border: 10px solid #f77776;
            &--img{
                padding: 20px 0 40px 0;
            }
        }
    }
    @media (max-width: 992px){
        .characteristic{
            &__content{
                padding: 15% 10px 10px 10px;
                &--p01{
                    font-size: 2vw;
                }
            }
        }
    }
    @media (max-width: 768px){
        .characteristic{
            &__content{
                 height: 26rem;
                 padding: 22% 10px 10px 10px;
                &--p01{
                    font-size: 14px;
                }
            }
            &__content03{
                &--img{
                    padding: 20px 0;
                }
            }
        }
    }
    @media (max-width: 576px){
        .container-fluid{
            margin-top: 50px;
        }
        .characteristic{
            margin-bottom: 0px;
            &__content{
                height: auto;
                padding: 7% 20px 0px 20px;
                margin-bottom: 50px;
            }
            h2{
                font-size: 25px;
                span{
                    font-size: 40px;
                }
                small{
                    font-size: 30px;
                }
            }
        }
    }
    .banner{
        img{
            max-width: 100%;
            width: 100vw;
        }
    }
    /*求人掲載の流れ*/
    .flow {
        width: 100vw;
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        background-color: #cff6ff;
        &__box{
            background-color: #fff;
            height: 270px;
            margin-right: 40px;
            position: relative;
            box-sizing: content-box;
            align-content: center;
            &--step{
                color: #0e84d6;
                font-size: 1.2rem;
                text-align: left;
                span{
                    font-size: 3rem;
                    position: relative;
                    left: 0px;
                }
            }
            &--img{
                img{
                    display: block;
                    margin: auto;
                    width: 100px;
                }
            }
            &--p{
                color: #000;
                font-size: 1rem;
                padding-top: 10px;
            }
        }
        &__box::after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 160px 0 160px 40px;
            border-color: transparent transparent transparent #fff;
            position: absolute;
            right: -39px;
            top: 0;
        }
        &__boxred{
            background-color: #fd7373;
            &--step{
                color:#fff;
            }
            img{
                width: 120px;
            }
        }
        &__boxred::after{
            border-color: transparent transparent transparent #fd7373;
        }
        &__button{
            a{
                span{
                    color: #fff;
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                    background: linear-gradient(#f88382,#f56766);
                    box-shadow: 4px 4px #ec2f2d;
                    padding: 20px 0;
                }
            }
            a:hover{
                span{
                    border-bottom-color: transparent;
                    transform: translateY(0.1875em);
                    color: #fff;
                }
            }
        }
    }
    .flow .num-index {
        font-size: 1.2rem;
      }
      .flow .btn,
      .flow .navbar .nav-item a[data-menu=会員登録],
      .navbar .nav-item .flow a[data-menu=会員登録],
      .flow .navbar .nav-item a[data-menu=ログイン],
      .navbar .nav-item .flow a[data-menu=ログイン],
      .flow .navbar .nav-item a[data-menu=資料請求],
      .navbar .nav-item .flow a[data-menu=資料請求] {
        font-size: 1.3rem;
      }
      
      .flow .btn span,
      .flow .navbar .nav-item a[data-menu=会員登録] span,
      .navbar .nav-item .flow a[data-menu=会員登録] span,
      .flow .navbar .nav-item a[data-menu=ログイン] span,
      .navbar .nav-item .flow a[data-menu=ログイン] span,
      .flow .navbar .nav-item a[data-menu=資料請求] span,
      .navbar .nav-item .flow a[data-menu=資料請求] span {
        color: #333;
        font-size: 0.8rem;
        display: block;
        margin-top: 5px;
      }

      .flow .row div:nth-last-child(1) .step {
        color: #fff;
      }
      .flow .row div:nth-last-child(1) .step .num {
        color: #fd7373;
        text-shadow: white 3px 0px 0px, white 2.83487px 0.981584px 0px, white 2.35766px 1.85511px 0px, white 1.62091px 2.52441px 0px, white 0.705713px 2.91581px 0px, white -0.287171px 2.98622px 0px, white -1.24844px 2.72789px 0px, white -2.07227px 2.16926px 0px, white -2.66798px 1.37182px 0px, white -2.96998px 0.42336px 0px, white -2.94502px -0.571704px 0px, white -2.59586px -1.50383px 0px, white -1.96093px -2.27041px 0px, white -1.11013px -2.78704px 0px, white -0.137119px -2.99686px 0px, white 0.850987px -2.87677px 0px, white 1.74541px -2.43999px 0px, white 2.44769px -1.73459px 0px, white 2.88051px -0.838247px 0px;
      }
      .flow .step {
        font-size: 1.6rem;
        color: #0e84d6;
        margin-top: -18px !important;
      }
      .flow .step .num {
        font-size: 3rem;
        position: relative;
        left: 4px;
        text-shadow: white 3px 0px 0px, white 2.83487px 0.981584px 0px, white 2.35766px 1.85511px 0px, white 1.62091px 2.52441px 0px, white 0.705713px 2.91581px 0px, white -0.287171px 2.98622px 0px, white -1.24844px 2.72789px 0px, white -2.07227px 2.16926px 0px, white -2.66798px 1.37182px 0px, white -2.96998px 0.42336px 0px, white -2.94502px -0.571704px 0px, white -2.59586px -1.50383px 0px, white -1.96093px -2.27041px 0px, white -1.11013px -2.78704px 0px, white -0.137119px -2.99686px 0px, white 0.850987px -2.87677px 0px, white 1.74541px -2.43999px 0px, white 2.44769px -1.73459px 0px, white 2.88051px -0.838247px 0px;
      }
      
      @media (max-width: 991.98px) {
        .flow{
            &__box{
                margin-bottom: 30px;
                margin-right: 0px;
                border-width: 30px 30px 0 30px;
                border-color: #fff transparent transparent transparent;
                right: 0;
                left: 0;
                top: auto;
                bottom: -25px;
                &--step{
                    text-align: center;
                }
              }
              &__box::after{
                border-width: 30px 0 30px 20px;
                right: 0px;
                left: 0px;
                margin: 0 auto;
                top: 93%;
                transform: rotate(90deg);
              }
              &__boxred::after{
                  display: none;
              }
              &__button{
                span{
                    width: 100%;
                }
            }
        }
        .flow .row {
            align-items: center;
          }
          .flow .row div:nth-last-child(1) .flow__box:after {
            content: none;
          }
          .flow .step {
            font-size: 1.3rem;
          }
          .flow .step .num {
            font-size: 2.4rem;
          }
          .flow .index {
            color: #333;
            font-size: 1rem;
            text-align: left !important;
            font-weight: normal !important;
          }
          .flow .navbar .nav-item a[data-menu=会員登録],
          .navbar .nav-item .flow a[data-menu=会員登録],
          .flow .navbar .nav-item a[data-menu=ログイン],
          .navbar .nav-item .flow a[data-menu=ログイン],
          .flow .navbar .nav-item a[data-menu=資料請求],
          .navbar .nav-item .flow a[data-menu=資料請求] {
            display: block;
            width: 400px;
            margin: auto;
          }
    }
      @media (max-width: 767.98px) {
        .flow .step .num {
          font-size: 2.1rem;
        }
        .flow .num-index {
            font-size: 0.9rem;
          }
      }
/*よくある質問*/
    .faq .index {
        color: #fff;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      
      .faq .index.q {
        background-color: #73D0FD;
      }
      
      .faq .index.a {
        background-color: #fd7373;
      }
      .faq a{
        color:#00b8ff;
      }
      .faq a:hover{
        color:#003a52;
      }
      .faq .row:after {
        content: "";
        display: block;
        width: 50%;
      }
    }/*#main*/
    /*footer*/
#wrapper-footer{
    background: #ececec;
    padding: 30px 0;
    .copyright{
        text-align: center;
    }
}
footer{
    .iframe{
        display: inline-block;
    }
    .site-info{
        width: 67%;
        vertical-align: top;
        display: inline-block;
        margin-left: 20px;
        &__head{
            background: #dfe0e0;
            padding: 10px;
        }
        &__list{
            padding: 0px; 
            border: 0.5px solid #9c9c9c;
            padding: 10px;
            list-style: none;
            background: #ffff;
        }
        &__link{
            width: 100%;
            border-top: 1px solid #dfe0e0;
            margin-left: 20px;
            padding: 20px 0 0 0;
            margin-top: 20%;
            li{
                position: relative;
                padding-right: 30px;
                a{
                    color: #000;
                }
            }
            li::before{
                position: absolute;
                top: 6px;
                left: -15px;
                content:"";
                display: block;
                width: 10px;
                height: 10px;
                border: 1px solid;
                border-color: #000 #000 transparent transparent;
                transform: rotate(45deg);
            }
        }
    }
    @media (max-width: 1200px){
        .site-info{
            width: 100%;
            margin: 20px 0 40px 0;
            &__link{
                bottom: -40px;
                margin-top: 20px;
            }
        }
        .iframe{
            width: 100%;
            iframe{
                width: 100%;
            }
        }
    }
}
/*top*/
.worry{
    position: relative;
    h2{
        span{
            font-size: 60px;
            color:#335fc1;
            position: relative;
        }
        span:after,span:before{
            content:"";
            display: block;
            position: absolute;
            border-bottom: 3px solid #335fc1;
            width: 11rem;
            left: 0;
        }
        span:before{
            bottom: 9px;
        }
        span:after{
            bottom: 3px;
        }
    }
    &__row{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        &--img{
            -moz-box-shadow: 0px 1px 17px -7px rgba(0, 0, 0, 0.8);
            -webkit-box-shadow: 0px 1px 17px -7px rgba(0, 0, 0, 0.8);
            -ms-box-shadow: 0px 1px 17px -7px rgba(0, 0, 0, 0.8);
            box-shadow: 0px 1px 17px -7px rgba(0, 0, 0, 0.8);
        }
        &--ul{
            list-style: none;
            li{
                margin-bottom: 35px;
                padding: 10px 10px 10px 80px;
                font-weight: bold;
                border: 1px solid #eee;
                -moz-box-shadow: 14px 15px 0px -7px rgba(0, 0, 0, 0.13);
                -webkit-box-shadow: 14px 15px 0px -7px rgba(0, 0, 0, 0.13);
                -ms-box-shadow: 14px 15px 0px -7px rgba(0, 0, 0, 0.13);
                box-shadow: 14px 15px 0px -7px rgba(0, 0, 0, 0.13);
                position: relative;
                font-size: 1.4rem;
                span{
                    background: linear-gradient(transparent 40%, #ffff00 45%);
                }
            }
            li:before{
                content:"";
                display: inline-block;
                background-image: url(../images/check.png);
                width: 4rem;
                background-size: cover;
                background-repeat: no-repeat;
                height: 52px;
                position: absolute;
                left: 0;
                top: -21px;
            }
        }
    }
    &__speech-bubble{
        text-align: center;
        padding: 50px 0;
        z-index: 0;
        position: relative;
        &--bg{
            background-color: #d9f2fe;
            height: 110%;
            bottom: 0%;
            width: 177%;
            position: absolute;
            display: block;
            z-index: -1;
            margin-left: calc((100% - 100vw)/2);
            margin-right: calc((100% - 100vw)/2);
        }
        p{
            font-size: 2.5rem;
            font-weight: bold;
            @include text-stroke(2, #fff);
            position: relative;
            small{
                font-size: 1.2rem;
                font-weight: bold;
            }
            span:first-child{
                color: #218896;
            }
            span:nth-child(3){
                background: linear-gradient(transparent 40%, #15aee9 45%);
            }
            span:nth-child(4){
                background: linear-gradient(transparent 40%, #f77776 45%);
            }
        }
        p:before,p:after{
            content:"";
            display: inline-block;
            width: 105px;
            height: 100px;
            position: absolute;
            bottom: 0;
        }
        p:before{
            background-image: url(../images/deco01.png);
            left: 0%;
        }
        p:after{
            background-image: url(../images/deco02.png);
            right: 0%;
        }
    }
    &__speech-bubble:before{
        content:"";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-right: 100px solid transparent;
        border-left: 100px solid transparent;
        border-top: 50px solid #fff;
        top: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    @media (max-width: 1200px){
        &__row{
            &--ul{
                li{
                    font-size: 1rem;
                }
            }
        }
        &__speech-bubble{
            p{
                font-size: 2rem;
            }
        }
    }
    @media (max-width: 992px){
        &__speech-bubble{
            p{
                font-size: 1.5rem;
            }
            p:before{
                left: -7%;
            }
            p:after{
                right: -5%;
            }
        }
    }
    @media (max-width: 768px){
        h2{
            span{
                font-size: 40px;
                border-bottom: double 3px #335fc1;
                display: inline-block;
            }
            span:before,span:after{
                display: none;
            }
        }
        &__row{
            .col-md-4{
                text-align: center;
                margin-bottom: 30px;
            }
            &--ul{
                padding: 0px;
                li{
                    padding: 10px 10px 10px 50px;
                }
            }
        }
        &__speech-bubble:before{
            top: -30px;
        }
        &__speech-bubble{
            p{
                font-size: 1rem;
            }
            p:before{
                left: -5%;
                background-size: 50%;
                background-repeat: no-repeat;
                top: 0%;
            }
            p:after{
                right: -15%;
                background-size: 50%;
                background-repeat: no-repeat;
                top: 0%;
            }
        }
    }
    @media (max-width: 576px){
        &__row{
            &--ul{
                li{
                    padding: 10px 10px 10px 50px;
                }
                li:before{
                    background-size: 80%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
        &__speech-bubble:before{
            top: -36px;
        }
        &__speech-bubble{
            p:before,p:after{
                display: none;
            }
        }
    }
}
.tenshoku-flow{
    h2{
        img{
            width: 2rem;
            margin-right: 10px;
            margin-bottom: 5px;
        }
    }
    position: relative;
    &__bg{
        background-color: #d9f2fe;
        height: 100%;
        bottom: 0%;
        width: 177%;
        position: absolute;
        display: block;
        z-index: -1;
        margin-left: calc((100% - 100vw)/2);
        margin-right: calc((100% - 100vw)/2);
    }
    &__box{
        background-color: #fff;
        text-align: center;
        height: 26rem;
        margin-right: 32px;
        position: relative;
        &--step{
            position: absolute;
            top: -21px;
            left: 0;
            font-size: 1.5rem;
            line-height: 2rem;
            right: 0;
            color: #0f84d6;
            span.num{
                font-size: 3rem;
            }
        }
        &--img{
            >p{
                text-align: left;
                padding: 0px;
                a{
                    color:#15aee9;
                }
                a:hover{
                    opacity: 0.8;
                }
            }
            img{
                width: 40%;
                margin: 30px 0 20px 0;
            }
        }

    }
    
    &__box:after{
        position: absolute;
        right: -30px;
        top: 0px;
        content:"";
        display: block;
        width: 0;
        height: 0;
        width: 0;
        height: 0;
        border-left: 30px solid #fff;
        border-top: 209px solid transparent;
        border-bottom: 209px solid transparent;
    }
    &__boxred{
        background-color: #fd7373;
        >p{
            color: #fff;
            span:first-child{
                color: #fd7373;
                text-shadow: 2px 2px #fff, -2px -2px 0 #fff, -2px 2px 0 #fff, 2px -2px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, -2px 0 0 #fff, 2px 0 0 #fff;
            }
        }
    }
    &__boxred:after{
        position: absolute;
        right: -30px;
        top: 0px;
        content:"";
        display: block;
        width: 0;
        height: 0;
        width: 0;
        height: 0;
        border-left: 30px solid #fd7373;
        border-top: 209px solid transparent;
        border-bottom: 209px solid transparent;
    }
    &__button{
        a{
            span{
                font-size: 1.3rem;
                color: #fff;
                font-weight: 700;
                width: 100%;
                text-align: center;
                background: -webkit-gradient(linear,left top,left bottom,from(#f88382),to(#f56766));
                background: -webkit-linear-gradient(#f88382,#f56766);
                background: -moz-linear-gradient(#f88382,#f56766);
                background: -o-linear-gradient(#f88382,#f56766);
                background: linear-gradient(#f88382,#f56766);
                -webkit-box-shadow: 4px 4px #ec2f2d;
                box-shadow: 4px 4px #ec2f2d;
                padding: 20px 0;
            }
        }
        a:hover{
            span{
                border-bottom-color: transparent;
                transform: translateY(0.1875em);
                color: #fff;
            }
        }
    }
    @media (max-width: 992px){
        &__box{
            height: auto;
            margin-right: 0px;
            margin-bottom: 60px;
            &--img{
                img{
                    width: 20%;
                }
            }
        }
        &__box:after{
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-top: 30px solid #fff;
            border-bottom: unset;
            left: 0;
            right: 0;
            bottom: -30px;
            margin: 0 auto;
            top: unset;
        }
        &__boxred:after{
            display: none;
        }
    }
}
.faq{
    h2{
        img{
            margin-right: 10px;
        }
    }
}